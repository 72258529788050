import { DefinitionNode, Kind, OperationDefinitionNode } from 'graphql';
import { ApolloLink } from '@apollo/client';

const operationsWithTaxIds = ['careSiteUserProvidersSearch'];

function isInRequireTaxIdsOperations(node: DefinitionNode): node is OperationDefinitionNode {
    return node.kind === Kind.OPERATION_DEFINITION && node?.name?.kind === Kind.NAME && operationsWithTaxIds.includes(node.name.value);
}

export const includeTaxIdsLink = new ApolloLink((operation, forward) => {
    const o = operation.query.definitions.find(isInRequireTaxIdsOperations);
    if (o) {
        operation.setContext({
            headers: {
                'ADV-INCLUDE-TAX-IDS': '',
            },
        });
    }
    return forward(operation);
});
