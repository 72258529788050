import { IEligibilityState } from './types';
import { ELIGIBILITY_RECEIVED, IEligibilityAction } from './action';
import { IEligibilityRow } from 'backend/types/patient';

export const initialEligibilityState: IEligibilityState = {
    eligibilityRows: [],
};

const eligibilityReducer = (state: IEligibilityState = initialEligibilityState, action: IEligibilityAction): IEligibilityState => {
    switch (action.type) {
        case ELIGIBILITY_RECEIVED:
            return {
                ...state,
                eligibilityRows: action.eligibilityRows as IEligibilityRow[],
            };
        default:
            return state;
    }
};

export default eligibilityReducer;
