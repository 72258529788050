import { IEnvironmentState } from './types';
import { IEnvironmentAction, INITIAL_LOAD_RECEIVED, TOGGLE_LOADING_ACTION } from './action';
import { IPageContent } from 'backend/types/pageContent';

export const initialEnvironmentState: IEnvironmentState = {
    name: process.env.ENVIRONMENT_NAME,
    pageContent: {
        images: {
            logo: '',
            welcome: '',
        },
        widgetsHtml: [
            { size: 7, content: '' },
            { size: 7, content: '' },
            { size: 5, content: '' },
            { size: 5, content: '' },
        ],
    } as IPageContent,
    isLoading: 0,
};

const environmentReducer = (state: IEnvironmentState = initialEnvironmentState, action: IEnvironmentAction): IEnvironmentState => {
    switch (action.type) {
        case INITIAL_LOAD_RECEIVED:
            return {
                ...state,
                pageContent: action.pageContent as IPageContent,
            };
        case TOGGLE_LOADING_ACTION: {
            const isLoading: number = state.isLoading + (action.isLoading ? 1 : -1);
            return {
                ...state,
                isLoading: isLoading,
            };
        }
        default:
            return state;
    }
};

export default environmentReducer;
