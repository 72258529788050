import React, { useState, useRef, useEffect } from 'react';
import { DataGrid, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import apolloClient from 'util/apolloClient';
import { toast } from 'react-toastify';
import { IProvider } from 'backend/types/provider';
import { IPaging, IPagingSorting, ISorting } from 'backend/types/pagingSorting';
import { providerSelectTableColumnDefinitions } from './columnDefinitions';
import ProviderSelectSearchBar, { IProviderSelectSearchForm, SearchByOptions } from './ProviderSelectSearchBar';
import { UNFAVORITE_PROVIDER, FAVORITE_PROVIDER, SEARCH_PROVIDERS } from './Queries';

interface IProviderSelectTableProps {
    onProviderSelected?: (provider: IProvider) => void;
}

interface ICareSiteUserProviders {
    items: Array<IProvider>;
    totalCount: number;
}

const ProviderSelectTable = ({ onProviderSelected }: IProviderSelectTableProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [gridSortModel, setGridSortModel] = useState<Array<GridSortItem>>([{ field: 'name', sort: 'asc' } as GridSortItem]);
    const [pageSize, setPageSize] = useState<number>(15);
    const [searchForm, setSearchForm] = useState<IProviderSelectSearchForm>({ type: SearchByOptions.all, queryString: '' });
    const [providers, setProviders] = useState<Array<IProvider>>([]);

    const favorite = async (providerId: number) => {
        const provider = providers.find((x) => x.id === providerId);
        if (provider) {
            try {
                await apolloClient.mutate({
                    mutation: FAVORITE_PROVIDER,
                    variables: {
                        symphonyProviderId: provider.id,
                    },
                });
                provider.isFavorite = true;
                setProviders([...providers]);
            } catch (error) {
                console.log('Favorite provider error:', error);
                toast.error('Failed to favorite provider');
            }
        }
    };
    const unfavorite = async (providerId: number) => {
        const provider = providers.find((x) => x.id === providerId);
        if (provider) {
            try {
                await apolloClient.mutate({
                    mutation: UNFAVORITE_PROVIDER,
                    variables: {
                        symphonyProviderId: provider.id,
                    },
                });
                provider.isFavorite = false;
                setProviders([...providers]);
            } catch (error) {
                console.log('Unfavorite provider error:', error);
                toast.error('Failed to unfavorite provider');
            }
        }
    };

    const favoriteRef = useRef<(providerId: number) => void>(favorite);
    const unfavoriteRef = useRef<(providerId: number) => void>(unfavorite);
    const defaultColumns = useRef(providerSelectTableColumnDefinitions(favoriteRef, unfavoriteRef));

    favoriteRef.current = favorite;
    unfavoriteRef.current = unfavorite;

    useEffect(() => {
        reload(searchForm, getPagingSortingModel(page, pageSize, gridSortModel[0] as GridSortItem));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reload = async (searchFields: IProviderSelectSearchForm, pagingSortingParams: IPagingSorting) => {
        setIsLoading(true);
        try {
            const variables = {
                type: searchFields?.type.toString(),
                queryString: searchFields?.queryString,
                take: pagingSortingParams.paging.take,
                skip: pagingSortingParams.paging.skip,
                orderBy: pagingSortingParams.sorting.orderBy,
                ascDesc: pagingSortingParams.sorting.ascDesc,
            };
            const response = await apolloClient.query({
                query: SEARCH_PROVIDERS,
                variables: variables,
                fetchPolicy: 'no-cache',
            });
            const { items, totalCount } = response.data.careSiteUserProvidersSearch as ICareSiteUserProviders;
            setProviders(items);
            setTotalCount(totalCount);
        } catch (error) {
            console.log(error);
            toast.error('Internal error while loading providers');
        } finally {
            setIsLoading(false);
        }
    };

    const onSearch = async (data: IProviderSelectSearchForm) => {
        setSearchForm(data);
        reload(data, getPagingSortingModel(page, pageSize, gridSortModel[0] as GridSortItem));
    };
    const onPageChange = (nextPage: number) => {
        setPage(nextPage);
        reload(searchForm, getPagingSortingModel(nextPage, pageSize, gridSortModel[0] as GridSortItem));
    };

    const onPageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(0);
        reload(searchForm, getPagingSortingModel(0, newPageSize, gridSortModel[0] as GridSortItem));
    };

    const onSortModelChange = (model: GridSortModel) => {
        setGridSortModel(model);
        setPage(0);
        reload(searchForm, getPagingSortingModel(0, pageSize, model[0] as GridSortItem));
    };

    const getPagingSortingModel = (page: number, pageSize: number, gridSortItem: GridSortItem): IPagingSorting => {
        return {
            paging: getPagingModel(page, pageSize),
            sorting: getSortingModel(gridSortItem),
        } as IPagingSorting;
    };

    const getPagingModel = (page: number, pageSize: number) => {
        return { skip: page * pageSize, take: pageSize } as IPaging;
    };

    const getSortingModel = (gridSortItem: GridSortItem) => {
        return {
            orderBy: gridSortItem != null ? gridSortItem.field : null,
            ascDesc: gridSortItem != null ? gridSortItem.sort : null,
        } as ISorting;
    };

    return (
        <Grid container direction="row" spacing={0}>
            <Grid item xs={12}>
                <ProviderSelectSearchBar defaultValues={searchForm} onSearch={onSearch}></ProviderSelectSearchBar>
            </Grid>
            <Grid item xs={12}>
                <DataGrid
                    getRowId={(row) => row.id}
                    aria-label="provider-select-grid"
                    autoHeight
                    rows={providers}
                    rowCount={totalCount}
                    columns={defaultColumns.current}
                    paginationMode="server"
                    page={page}
                    onPageChange={onPageChange}
                    pageSize={pageSize}
                    rowsPerPageOptions={[15, 25]}
                    onPageSizeChange={onPageSizeChange}
                    sortingMode="server"
                    sortModel={gridSortModel}
                    onSortModelChange={onSortModelChange}
                    isRowSelectable={() => true}
                    onRowClick={(e) => onProviderSelected && onProviderSelected(e.row as IProvider)}
                    density="compact"
                    loading={isLoading}
                    hideFooterSelectedRowCount={true}
                    getRowClassName={() => 'cell-pointer-class'}
                />
            </Grid>
        </Grid>
    );
};

export default ProviderSelectTable;
