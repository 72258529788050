import React, { ComponentType, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { IState } from 'store';
import { initialLoad } from 'store/environment/middleware';
import PageWrapper from '../templates/PageWrapper';
import { NotFoundPath, ProviderSelectPath } from './Path';

interface PrivateRouteProps {
    targetComponent: ComponentType<RouteComponentProps> | ComponentType;
    path: string;
    exact?: boolean;
}

const PrivateRoute = ({ targetComponent: Component, path, exact = true, ...rest }: PrivateRouteProps) => {
    const dispatch = useDispatch();

    const isLoggedIn = useSelector((state: IState) => state.auth.isLoggedIn);
    const isProviderSelected = useSelector((state: IState) => state.auth.isProviderSelected);
    const isProviderSelectPage = path === ProviderSelectPath;

    useEffect(() => {
        if (isLoggedIn && isProviderSelected) {
            dispatch(initialLoad());
        }
    }, [dispatch, isLoggedIn, isProviderSelected]);

    return (
        <Route
            {...rest}
            path={path}
            render={(props) =>
                !isLoggedIn ? (
                    <Redirect to={NotFoundPath} />
                ) : isProviderSelectPage || isProviderSelected ? (
                    <PageWrapper showHeader={!isProviderSelectPage}>
                        <Component {...props} />
                    </PageWrapper>
                ) : (
                    <Redirect to={ProviderSelectPath} />
                )
            }
            exact={exact}
        />
    );
};

export default PrivateRoute;
