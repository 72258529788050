import { SxProps, Theme } from '@mui/material';

const globalStyles = {
    generalLoading: {
        position: 'fixed',
        width: '100%',
        top: 0,
        left: 0,
    } as SxProps<Theme>,
    buttonsRow: {
        '& button': {
            height: '37px',
            mt: 1,
            verticalAlign: 'normal',
        },
        display: 'flex',
        alignItems: 'top',
    } as SxProps<Theme>,
    verticalBar: {
        borderLeft: '1px solid',
        borderLeftColor: 'secondary.light',
        height: '25px',
        margin: '6px 0 6px 12px',
    } as SxProps<Theme>,
    searchBarForm: {
        mx: -2,
        px: 2,
        pt: 1,
        pb: 2,
        backgroundColor: 'secondary.main',
    } as SxProps<Theme>,
} as const;

export default globalStyles;
