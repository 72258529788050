import React, { useState } from 'react';
import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

const styles = {
    outerContainer: { mt: 'auto' } as SxProps<Theme>,
    innerContainer: { backgroundColor: 'secondary.main', mt: 2, py: 1, textAlign: 'center' } as SxProps<Theme>,
} as const;

const Footer = () => {
    const [year] = useState(new Date().getFullYear());
    return (
        <Box component="div" sx={styles.outerContainer}>
            <Box component="div" sx={styles.innerContainer}>
                © {year} Advantasure Inc. All Rights Reserved.
            </Box>
        </Box>
    );
};

export default Footer;
