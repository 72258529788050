export const ProviderSelectPath = '/provider-select';
export const EligibilityDetailsPath = '/eligibility/details/:memberId';
export const EligibilityPath = '/eligibility';
export const AuthorizationsPath = '/authorizations';
export const ClaimsPath = '/claims';
export const RiskQualityPath = '/riskquality';
export const ResourcesPath = '/resources';
export const LoginTokenPath = '/login-token';
export const NotFoundPath = '/not-found';
export const HomePath = '/';
