import { Dispatch } from 'redux';
import { NotFoundPath } from 'routes/Path';
import axiosClient from 'util/axiosHttp';
import { authFail, authRedirectToProviderSelect, authStart, authSuccess, changeProviderFail, logoutSuccess } from './action';
import { IAuthState, IAuthUser } from './types';
import { History } from 'history';

export const loginWithToken = (token: string) => {
    return async (dispatch: Dispatch): Promise<void> => {
        localStorage.removeItem('authUser');
        dispatch(authStart());
        if (token) {
            try {
                const result = await axiosClient.post('v1/account/login/login-token', { token }, { withCredentials: true });
                if (result.status == 200) {
                    if (result.data.isProviderSelected === false) {
                        localStorage.setItem('authUser', JSON.stringify({}));
                        dispatch(authRedirectToProviderSelect());
                    } else {
                        const authUser = result.data as IAuthUser;
                        localStorage.setItem('authUser', JSON.stringify(authUser));
                        dispatch(authSuccess(authUser));
                    }
                } else {
                    dispatch(authFail('Internal error while logging in'));
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
                let message = err.message;
                if (err.response && err.response.data) {
                    message = err.response.data;
                }
                dispatch(authFail(message));
            }
        } else {
            dispatch(authFail('Token not provided.'));
        }
    };
};

export const changeProvider = (symphonyProviderId: number, refreshPageAfterProviderChange: boolean, history: History) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            const result = await axiosClient.post('v1/account/login/as-different-provider/' + symphonyProviderId, {}, { withCredentials: true });
            if (result.status == 200) {
                const authUser = result.data as IAuthUser;
                localStorage.setItem('authUser', JSON.stringify(authUser));
                dispatch(authSuccess(authUser));
                if (refreshPageAfterProviderChange) {
                    //React router does not support reload functionality - https://github.com/remix-run/react-router/issues/1982
                    const p = history.location.pathname;
                    history.push({ pathname: NotFoundPath });
                    history.replace({ pathname: p });
                }
            } else {
                dispatch(changeProviderFail('Internal error while changing provider'));
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            let message = err.message;
            if (err.response && err.response.data) {
                message = err.response.data;
            }
            dispatch(changeProviderFail(message));
        }
    };
};

export const logout = (loginUrl: string) => {
    return async (dispatch: Dispatch): Promise<void> => {
        axiosClient.post('v1/account/logout', {}, { withCredentials: true }).then((result) => {
            if (result.status == 200) {
                localStorage.removeItem('authUser');
                dispatch(logoutSuccess());
                window.location.href = loginUrl;
            }
        });
    };
};

export const removeAuth = () => {
    localStorage.removeItem('authUser');
    return logoutSuccess();
};

export const handleExpiredSession = (auth: IAuthState, dispatch: Dispatch) => {
    let loginUrl = '';
    if (auth && auth.currentUser) {
        loginUrl = auth.currentUser.loginUrl;
    }
    localStorage.removeItem('authUser');
    dispatch(removeAuth());
    window.location.href = loginUrl ? loginUrl : `/not-found`;
};
