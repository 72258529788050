import { AUTH_FAIL, AUTH_REDIRECT_TO_PROVIDER_SELECT, AUTH_START, AUTH_SUCCESS, CHANGE_PROVIDER_FAIL, ILoginActions, LOGOUT_SUCCESS } from './action';
import { IAuthState, IAuthUser } from './types';

export const initialAuthState: IAuthState = getInitialState();

function getInitialState(): IAuthState {
    const lsUser = localStorage.getItem('authUser');
    let user = {} as IAuthUser;
    let isLoggedIn = false;
    let isProviderSelected = false;
    if (lsUser) {
        user = JSON.parse(lsUser) as IAuthUser;
        isLoggedIn = true;
        isProviderSelected = !!user.providerFullName;
    }

    return {
        currentUser: user,
        isLoggedIn: isLoggedIn,
        isProviderSelected: isProviderSelected,
        error: '',
    };
}

const authReducer = (state: IAuthState = initialAuthState, action: ILoginActions): IAuthState => {
    switch (action.type) {
        case AUTH_START:
            return {
                ...state,
                currentUser: {} as IAuthUser,
                isLoggedIn: false,
                isProviderSelected: false,
                error: '',
            };
        case AUTH_REDIRECT_TO_PROVIDER_SELECT:
            return {
                ...state,
                isLoggedIn: true,
            };
        case AUTH_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                currentUser: action.user ? action.user : ({} as IAuthUser),
                isProviderSelected: true,
                error: '',
            };
        case AUTH_FAIL:
            return {
                ...state,
                error: action.error,
                isLoggedIn: false,
            };
        case CHANGE_PROVIDER_FAIL:
            return {
                ...state,
                error: action.error,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                currentUser: {} as IAuthUser,
            };
        default:
            return state;
    }
};

export default authReducer;
