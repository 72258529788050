import { IPageContent } from 'backend/types/pageContent';

export const INITIAL_LOAD_RECEIVED = 'INITIAL_LOAD_RECEIVED';
export type INITIAL_LOAD_RECEIVED = typeof INITIAL_LOAD_RECEIVED;

export const TOGGLE_LOADING_ACTION = 'TOGGLE_LOADING_ACTION';
export type TOGGLE_LOADING_ACTION = typeof TOGGLE_LOADING_ACTION;

export interface IInitialLoadReceivedAction {
    type: INITIAL_LOAD_RECEIVED;
    pageContent: IPageContent;
}

export interface IToggleLoadingAction {
    type: TOGGLE_LOADING_ACTION;
    isLoading: boolean;
}

export const initialLoadReceived = (pageContent: IPageContent): IInitialLoadReceivedAction => {
    return {
        type: INITIAL_LOAD_RECEIVED,
        pageContent: pageContent as IPageContent,
    };
};

export const toggleLoadingAction = (isLoading: boolean): IToggleLoadingAction => {
    return {
        type: TOGGLE_LOADING_ACTION,
        isLoading: isLoading,
    };
};

export type IEnvironmentAction = IInitialLoadReceivedAction | IToggleLoadingAction;
