import { IAuthUser } from './types';

export const AUTH_START = 'AUTH_START';
export type AUTH_START = typeof AUTH_START;

export const AUTH_REDIRECT_TO_PROVIDER_SELECT = 'AUTH_REDIRECT_TO_PROVIDER_SELECT';
export type AUTH_REDIRECT_TO_PROVIDER_SELECT = typeof AUTH_REDIRECT_TO_PROVIDER_SELECT;

export const AUTH_FAIL = 'AUTH_FAIL';
export type AUTH_FAIL = typeof AUTH_FAIL;

export const CHANGE_PROVIDER_FAIL = 'CHANGE_PROVIDER_FAIL';
export type CHANGE_PROVIDER_FAIL = typeof CHANGE_PROVIDER_FAIL;

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export type AUTH_SUCCESS = typeof AUTH_SUCCESS;

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export type LOGOUT_SUCCESS = typeof LOGOUT_SUCCESS;

export interface IAuthStartAction {
    type: AUTH_START;
}

export const authStart = (): IAuthStartAction => {
    return {
        type: AUTH_START,
    };
};

export interface IAuthRedirectToProviderSelectAction {
    type: AUTH_REDIRECT_TO_PROVIDER_SELECT;
}

export const authRedirectToProviderSelect = (): IAuthRedirectToProviderSelectAction => {
    return {
        type: AUTH_REDIRECT_TO_PROVIDER_SELECT,
    };
};

export interface IAuthSuccessAction {
    type: AUTH_SUCCESS;
    user: IAuthUser;
}
export const authSuccess = (authUser: IAuthUser): IAuthSuccessAction => {
    return {
        type: AUTH_SUCCESS,
        user: authUser ? authUser : ({} as IAuthUser),
    };
};

export interface IAuthFailAction {
    type: AUTH_FAIL;
    error: string;
}

export const authFail = (error: string): IAuthFailAction => {
    return {
        type: AUTH_FAIL,
        error: error,
    };
};

export interface IChangeProviderFailAction {
    type: CHANGE_PROVIDER_FAIL;
    error: string;
}

export const changeProviderFail = (error: string): IChangeProviderFailAction => {
    return {
        type: CHANGE_PROVIDER_FAIL,
        error: error,
    };
};

export interface ILogoutSuccessAction {
    type: LOGOUT_SUCCESS;
}
export const logoutSuccess = (): ILogoutSuccessAction => {
    return {
        type: LOGOUT_SUCCESS,
    };
};

export type ILoginActions = IAuthStartAction | IAuthRedirectToProviderSelectAction | IAuthSuccessAction | IAuthFailAction | IChangeProviderFailAction | ILogoutSuccessAction;
