import React from 'react';
import globalStyles from 'config/cssHooks';
import { LinearProgress } from '@mui/material';
import { IState } from 'store';
import { useSelector } from 'react-redux';

const TopLoadingBar = () => {
    const isLoading = useSelector((state: IState) => state.environment.isLoading) > 0;

    return <>{isLoading ? <LinearProgress sx={globalStyles.generalLoading} /> : null}</>;
};

export default TopLoadingBar;
