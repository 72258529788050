import { IHeaderActions, SELECT_TAB } from './actions';
import { IHeaderState } from './types';

export const initialHeaderState: IHeaderState = {
    selectedTabNo: 0,
};

const headerReducer = (state: IHeaderState = initialHeaderState, actions: IHeaderActions): IHeaderState => {
    switch (actions.type) {
        case SELECT_TAB:
            return {
                ...state,
                selectedTabNo: actions.tabNo,
            };
        default:
            return state;
    }
};
export default headerReducer;
