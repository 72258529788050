import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import { AuthorizationsPath, ClaimsPath, EligibilityDetailsPath, EligibilityPath, HomePath, LoginTokenPath, NotFoundPath, ProviderSelectPath, ResourcesPath, RiskQualityPath } from './Path';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
    const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
    const Home = React.lazy(() => import('../pages/home'));
    const ProviderSelect = React.lazy(() => import('../pages/providerSelect'));
    const Eligibility = React.lazy(() => import('../pages/eligibility'));
    const EligibilityDetails = React.lazy(() => import('../pages/eligibility/details'));
    const LoginToken = React.lazy(() => import('../pages/loginToken'));
    const NotFound = React.lazy(() => import('../pages/notFound'));
    const Authorizations = React.lazy(() => import('../pages/authorizations'));
    const Claims = React.lazy(() => import('../pages/claims'));
    const RiskQuality = React.lazy(() => import('../pages/riskquality'));
    const Resources = React.lazy(() => import('../pages/resources'));

    return (
        <>
            <BrowserRouter>
                <React.Suspense fallback={loading()}>
                    <Switch>
                        <PrivateRoute path={ProviderSelectPath} targetComponent={ProviderSelect} />
                        <PrivateRoute path={EligibilityDetailsPath} targetComponent={EligibilityDetails} />
                        <PrivateRoute path={EligibilityPath} targetComponent={Eligibility} />
                        <PrivateRoute path={AuthorizationsPath} targetComponent={Authorizations} />
                        <PrivateRoute path={ClaimsPath} targetComponent={Claims} />
                        <PrivateRoute path={RiskQualityPath} targetComponent={RiskQuality} />
                        <PrivateRoute path={ResourcesPath} targetComponent={Resources} />
                        <PublicRoute path={LoginTokenPath} targetComponent={LoginToken} />
                        <PublicRoute path={NotFoundPath} targetComponent={NotFound} />
                        <PrivateRoute path={HomePath} targetComponent={Home} />
                        <PrivateRoute path="*" targetComponent={Home} />
                    </Switch>
                </React.Suspense>
            </BrowserRouter>
        </>
    );
};

export default Routes;
