import { GridCellValue } from '@mui/x-data-grid';
import moment from 'moment';

export const dateFnsShortDateFormat = 'MM/dd/yyyy';
export const momentShortDateFormat = 'MM/DD/YYYY';

export function delay(time: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export function isValidDate(d: Date | null | undefined): boolean {
    if (d == null) return true;
    if (Object.prototype.toString.call(d) === '[object Date]') {
        // it is a date
        if (isNaN(d.getTime())) {
            // date is not valid
            return false;
        } else {
            // date is valid
            return true;
        }
    } else {
        // not a date
        return false;
    }
}

export function formatDate(d: Date | null | undefined, format: string): string {
    return moment(d).format(format);
}

export function toDateString(d: Date | null | undefined): string {
    return toDateStringOrNull(d) ?? '';
}

export function toDateStringOrNull(d: Date | null | undefined): string | null {
    return d ? formatDate(d, momentShortDateFormat) : null;
}

export function fromGridCellValueToDateString(dt: GridCellValue, defaultValue = ''): string {
    if (dt === undefined || dt === null) return defaultValue;
    const d = dt as Date;
    return d ? toDateString(d) : dt?.toString().substring(0, 10);
}
