import { isValidDate } from './dateTimeHelpers';

export function requiredValidator(name: string) {
    return `${name} must be specified`;
}

export function maxLengthValidator(maxLength: number, name?: string) {
    return { value: maxLength, message: `${name ? name : 'Value'} too long` };
}

export function minLengthValidator(maxLength: number, name?: string) {
    return { value: maxLength, message: `${name ? name : 'Value'} too short` };
}

export function isValidDateValidator(name?: string) {
    return function (v: Date | null | undefined) {
        return isValidDate(v) || `Invalid ${name ? name + ' ' : ''}date`;
    };
}

export function isMaxDateValidator(name: string, max: Date) {
    return function (v: Date | null | undefined) {
        if (v == null || v === undefined) return true;
        return v?.getTime() <= max.getTime() || `Invalid ${name ? name + ' ' : ''}date`;
    };
}

export function isMinDateValidator(name: string, min: Date) {
    return function (v: Date | null | undefined) {
        if (v == null || v === undefined) return true;
        return min.getTime() <= v?.getTime() || `Invalid ${name ? name + ' ' : ''}date`;
    };
}
