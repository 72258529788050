import { gql } from '@apollo/client';

export const SEARCH_PROVIDERS = gql`
    query careSiteUserProvidersSearch($type: String, $queryString: String, $take: Int!, $skip: Int!, $orderBy: String, $ascDesc: String) {
        careSiteUserProvidersSearch(type: $type, queryString: $queryString, take: $take, skip: $skip, orderBy: $orderBy, ascDesc: $ascDesc) {
            items {
                id
                name
                taxID
                nPI
                isRecent
                isFavorite
            }
            totalCount
        }
    }
`;

export const FAVORITE_PROVIDER = gql`
    mutation favoriteProvider($symphonyProviderId: Int!) {
        provider {
            favoriteProvider(symphonyProviderId: $symphonyProviderId)
        }
    }
`;

export const UNFAVORITE_PROVIDER = gql`
    mutation unfavoriteProvider($symphonyProviderId: Int!) {
        provider {
            unfavoriteProvider(symphonyProviderId: $symphonyProviderId)
        }
    }
`;
