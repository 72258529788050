import { handleExpiredSession } from 'store/auth/middleware';
import { InMemoryCache, ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { toggleLoadingAction } from 'store/environment/action';
import store from 'store';
import appConfig from 'config/appConfig';
import { dateFormatLink } from './helpers/dateFormatLink';
import { includeTaxIdsLink } from './helpers/includeTaxIdsLink';

const toggleLoadingLink = new ApolloLink((operation, forward) => {
    store.dispatch(toggleLoadingAction(true));
    return forward(operation).map((result) => {
        store.dispatch(toggleLoadingAction(false));
        return result;
    });
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (networkError && 'statusCode' in networkError) {
        if (401 === networkError.statusCode) {
            const auth = store.getState().auth;
            handleExpiredSession(auth, store.dispatch);
        }
    }

    store.dispatch(toggleLoadingAction(false));
    console.log('Error from ApolloClient:', networkError, graphQLErrors);
});

const baseLink = new HttpLink({
    uri: appConfig.apiUrl + '/api/graphql/',
    credentials: 'include',
});

const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, toggleLoadingLink, dateFormatLink, includeTaxIdsLink, baseLink]),
});

export default apolloClient;
