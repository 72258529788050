import React, { useLayoutEffect, useState } from 'react';
import { Typography, Tab, Tabs, Grid, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from 'store';
import { selectTab } from 'store/header/actions';
import Login from './login';
import IsUserCreatedInSymphonyError from './IsUserCreatedInSymphonyError';
import { Link as RouterLink } from 'react-router-dom';
import { getImagePath, handleOnImageError } from 'util/helpers/imageHelpers';
import ProviderSelectDialog from 'components/provider/ProviderSelectDialog';
import { SxProps, Theme } from '@mui/system';

const styles = {
    logoContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        ml: 2,
    } as SxProps<Theme>,
    logoText: {
        fontWeight: 600,
        fontSize: '18px',
    } as SxProps<Theme>,
    removeDecorations: {
        textDecoration: 'none',
        color: 'inherit',
        textTransform: 'capitalize',
        paddingTop: '24px',
    } as SxProps<Theme>,
    ribbonHeight: {
        height: '32px',
    } as SxProps<Theme>,
} as const;

const Header = () => {
    const largeWindowWidth = 1200;
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const authUser = useSelector((state: IState) => state.auth.currentUser);
    const careSiteExternalId = useSelector((state: IState) => state.environment.pageContent.careSiteExternalId);
    const logo = useSelector((state: IState) => state.environment.pageContent.images.logo);
    const selectedTabNo = useSelector((state: IState) => state.header.selectedTabNo);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const onProviderClick = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useLayoutEffect(() => {
        function onResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    const handleChange = (_: React.SyntheticEvent, activeTabNo: number) => {
        dispatch(selectTab(activeTabNo));
    };

    const logoContent = () => {
        return (
            <Box component="div" sx={styles.logoContainer}>
                <img height="32px" src={getImagePath(logo, careSiteExternalId)} onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleOnImageError(e, logo)} />
                <Typography sx={styles.logoText} px={2}>
                    Provider portal
                </Typography>
            </Box>
        );
    };

    const tabsContent = () => {
        return (
            <>
                <Tabs textColor="inherit" value={selectedTabNo} onChange={handleChange} aria-label="tabs as pages or links to pages" variant="scrollable">
                    <Tab component={RouterLink} label="Home" to="/" sx={styles.removeDecorations} data-cy="home-tab" />
                    <Tab component={RouterLink} label="Eligibility" to="/eligibility/" sx={styles.removeDecorations} data-cy="eligibility-tab" />
                    <Tab component={RouterLink} label="Authorizations" to="/authorizations/" sx={styles.removeDecorations} data-cy="authorizations-tab" />
                    <Tab component={RouterLink} label="Claims" to="/claims/" sx={styles.removeDecorations} data-cy="claims-tab" />
                    <Tab component={RouterLink} label="Risk & Quality" to="/riskquality/" sx={styles.removeDecorations} data-cy="riskquality-tab" />
                    <Tab component={RouterLink} label="Resources" to="/resources/" sx={styles.removeDecorations} data-cy="resources-tab" />
                </Tabs>
            </>
        );
    };

    const getExtraLargeScreenLayout = () => {
        return (
            <Grid container spacing={0}>
                <Grid item container spacing={0} xs={12} sm={3} md={3} lg={3} xl={3} alignItems="center" py={2}>
                    {logoContent()}
                </Grid>
                <Grid item container spacing={0} xs={12} sm={9} md={9} lg={5} xl={5} justifyContent="center">
                    {tabsContent()}
                </Grid>
                <Grid item container spacing={0} xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Login onProviderClick={onProviderClick} />
                </Grid>
            </Grid>
        );
    };

    const getLargeToSmallScreenLayout = () => {
        return (
            <Grid container spacing={0}>
                <Grid item container spacing={0} xs={12} sm={6} md={6} lg={2} xl={2} alignItems="center" py={2}>
                    {logoContent()}
                </Grid>
                <Grid item container spacing={0} xs={12} sm={6} md={6} lg={3} xl={3}>
                    <Login onProviderClick={onProviderClick} />
                </Grid>
                <Grid item container spacing={0} xs={12} sm={12} md={12} lg={7} xl={7} justifyContent="center">
                    {tabsContent()}
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <IsUserCreatedInSymphonyError isUserCreatedInSymphony={authUser.isUserCreatedInSymphony} />
            {windowWidth > largeWindowWidth ? getExtraLargeScreenLayout() : getLargeToSmallScreenLayout()}
            <ProviderSelectDialog open={open} onClose={() => handleClose()}></ProviderSelectDialog>
        </>
    );
};

export default Header;
