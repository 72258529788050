import axios from 'axios';
import store from 'store';
import { handleExpiredSession } from 'store/auth/middleware';
import { toggleLoadingAction } from 'store/environment/action';
import appConfig from 'config/appConfig';

const axiosClient = axios.create({
    withCredentials: true,
    baseURL: appConfig.apiUrl + '/api/',
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
});

axiosClient.interceptors.request.use(
    (config) => {
        store.dispatch(toggleLoadingAction(true));
        return config;
    },
    (error) => {
        console.log('Error from AxiosClient request:', error);
        store.dispatch(toggleLoadingAction(false));
        Promise.reject(error);
    }
);

export interface IResponseLocation {
    line: number;
    column: number;
}

export interface IResponseExtension {
    code: string;
}

export interface IResponseError {
    message: string;
    path: string[];
    locations: IResponseLocation[];
    extensions: IResponseExtension;
}

axiosClient.interceptors.response.use(
    function (response) {
        store.dispatch(toggleLoadingAction(false));
        return response;
    },
    function (error) {
        store.dispatch(toggleLoadingAction(false));
        console.log('Error from AxiosClient response:', error);
        if (error.response && error.response.status === 401) {
            const auth = store.getState().auth;
            handleExpiredSession(auth, store.dispatch);
        } else {
            return Promise.reject(error);
        }
    }
);

export default axiosClient;
