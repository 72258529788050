export const SELECT_TAB = 'SELECT_TAB';
export type SELECT_TAB = typeof SELECT_TAB;

export interface ISelectTabAction {
    type: SELECT_TAB;
    tabNo: number;
}

export const selectTab = (tabNo: number): ISelectTabAction => {
    return {
        type: SELECT_TAB,
        tabNo: tabNo,
    };
};

export type IHeaderActions = ISelectTabAction;
