import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import RestoreIcon from '@mui/icons-material/Restore';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { IconButton, Tooltip } from '@mui/material';
import { IProvider } from 'backend/types/provider';

export const providerSelectTableColumnDefinitions = (
    favorite: React.MutableRefObject<((providerId: number) => void) | undefined>,
    unfavorite: React.MutableRefObject<((providerId: number) => void) | undefined>
) => {
    return [
        {
            field: 'isRecent',
            flex: 1,
            headerClassName: 'header-class',
            headerName: '',
            minWidth: 50,
            disableColumnMenu: true,
            sortable: false,
            align: 'center',
            renderHeader: () => {
                return <>&nbsp;</>;
            },
            renderCell: ({ row }: GridRenderCellParams<unknown, IProvider>) => {
                return (
                    <>
                        {row.isRecent && (
                            <Tooltip title="Recent" key={`${row.id}-isRecent`}>
                                <RestoreIcon />
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
        {
            field: 'isFavorite',
            flex: 1,
            headerClassName: 'header-class',
            headerName: '',
            minWidth: 50,
            disableColumnMenu: true,
            sortable: false,
            align: 'center',
            renderHeader: () => {
                return <>&nbsp;</>;
            },
            renderCell: ({ row }: GridRenderCellParams<unknown, IProvider>) => {
                return row.isFavorite ? (
                    <Tooltip title="Unfavorite" key={`${row.id}-isFavorite-True`}>
                        <IconButton
                            sx={{ color: 'other.yellow' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (unfavorite && unfavorite.current) unfavorite.current(row.id);
                            }}
                        >
                            <StarIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Favorite" key={`${row.id}-isFavorite-False`}>
                        <IconButton
                            key={`${row.id}-isNotFavorite`}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (favorite && favorite.current) favorite.current(row.id);
                            }}
                        >
                            <StarBorderIcon />
                        </IconButton>
                    </Tooltip>
                );
            },
        },
        {
            field: 'name',
            flex: 2,
            headerClassName: 'header-class',
            headerName: 'Name',
            minWidth: 200,
        },
        {
            field: 'nPI',
            flex: 2,
            headerClassName: 'header-class',
            headerName: 'NPI',
            minWidth: 150,
        },
        {
            field: 'taxID',
            flex: 2,
            headerClassName: 'header-class',
            headerName: 'Tax ID',
            minWidth: 150,
        },
        {
            field: 'id',
            flex: 2,
            headerClassName: 'header-class',
            headerName: 'Provider ID',
            minWidth: 150,
        },
    ] as GridColDef[];
};
