import React from 'react';
import { TextField, InputAdornment, IconButton, Box, Grid, ButtonGroup, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useForm } from 'react-hook-form';
import { minLengthValidator } from 'util/helpers/inputValidators';
import globalStyles from 'config/cssHooks';

export interface IProviderSelectSearchForm {
    queryString: string;
    type: SearchByOptions;
}

interface IProviderSelectSearchBarProps {
    defaultValues: IProviderSelectSearchForm;
    onSearch: (data: IProviderSelectSearchForm) => Promise<void>;
}

export enum SearchByOptions {
    all = 'all',
    favorites = 'favorites',
    recent = 'recent',
}

const ProviderSelectSearchBar = ({ defaultValues, onSearch }: IProviderSelectSearchBarProps) => {
    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        setValue,
        watch,
    } = useForm<IProviderSelectSearchForm>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: defaultValues,
    });

    register('type');
    const watchType = watch('type');

    return (
        <Box
            sx={globalStyles.searchBarForm}
            component="form"
            onSubmit={handleSubmit(async (data: IProviderSelectSearchForm) => {
                await onSearch(data);
            })}
        >
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        label="Search by Name, NPI, Tax ID"
                        {...register('queryString', {
                            minLength: minLengthValidator(3, 'Search parameter'),
                        })}
                        error={!!errors.queryString}
                        helperText={errors.queryString?.message}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type="submit" edge="end">
                                        <SearchIcon color="primary" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup fullWidth variant="outlined" aria-label="outlined button group">
                        <Button type="submit" variant={watchType === SearchByOptions.all ? 'contained' : 'outlined'} onClick={() => (isValid ? setValue('type', SearchByOptions.all) : null)}>
                            All
                        </Button>
                        <Button type="submit" variant={watchType === SearchByOptions.favorites ? 'contained' : 'outlined'} onClick={() => (isValid ? setValue('type', SearchByOptions.favorites) : null)}>
                            Favorites
                        </Button>
                        <Button type="submit" variant={watchType === SearchByOptions.recent ? 'contained' : 'outlined'} onClick={() => (isValid ? setValue('type', SearchByOptions.recent) : null)}>
                            Recent
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProviderSelectSearchBar;
