import { IEligibilityRow } from 'backend/types/patient';

export const ELIGIBILITY_RECEIVED = 'ELIGIBILITY_RECEIVED';
export type ELIGIBILITY_RECEIVED = typeof ELIGIBILITY_RECEIVED;

export interface IEligibilityReceivedAction {
    type: ELIGIBILITY_RECEIVED;
    eligibilityRows: IEligibilityRow[];
}

export const eligibilityReceived = (rows: IEligibilityRow[]): IEligibilityReceivedAction => {
    return {
        type: ELIGIBILITY_RECEIVED,
        eligibilityRows: rows,
    };
};

export type IEligibilityAction = IEligibilityReceivedAction;
