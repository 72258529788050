import { Dispatch } from 'redux';
import { initialLoadReceived } from './action';
import apolloClient from 'util/apolloClient';

import { IPageContent } from 'backend/types/pageContent';
import { gql } from '@apollo/client';
import { toast } from 'react-toastify';

const GET_PAGE_CONTENT = gql`
    query IPageContent {
        pageContent {
            jsonContent
            careSite {
                externalId
            }
        }
    }
`;

export const initialLoad = () => {
    return async (dispatch: Dispatch): Promise<void> => {
        apolloClient
            .query({
                query: GET_PAGE_CONTENT,
            })
            .then((response) => {
                const pageContent = JSON.parse(response.data.pageContent.jsonContent) as IPageContent;
                pageContent.careSiteExternalId = response.data.pageContent.careSite.externalId;
                dispatch(initialLoadReceived(pageContent));
            })
            .catch(() => {
                toast.error('Internal error while getting page content');
            });
    };
};
