import React, { ComponentType } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

interface IPublicRouteProps {
    targetComponent: ComponentType<RouteComponentProps> | ComponentType;
    path: string;
}
const PublicRoute = ({ targetComponent: Component, ...rest }: IPublicRouteProps) => {
    return <Route {...rest} render={(props) => <Component {...props} />} exact />;
};

export default PublicRoute;
