import React from 'react';
import { Grid } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

export interface IIsUserCreatedInSymphonyProps {
    isUserCreatedInSymphony: boolean;
}

const IsUserCreatedInSymphonyError = ({ isUserCreatedInSymphony }: IIsUserCreatedInSymphonyProps) => {
    return (
        <>
            {!isUserCreatedInSymphony && (
                <Grid item xs={12}>
                    <Alert severity="error">
                        <AlertTitle>Failed to connect user with Symphony.</AlertTitle>
                        Pages might not work properly. Make sure that Symphony is working. Try to log out and log in again. If error persists, contact system Administrator.
                    </Alert>
                </Grid>
            )}
        </>
    );
};

export default IsUserCreatedInSymphonyError;
