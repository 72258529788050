import Header from 'components/header';
import Footer from 'components/footer';
import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import TopLoadingBar from 'components/loading/TopLoadingBar';

interface IPageWrapperProps {
    showHeader?: boolean;
    children: ReactNode;
}

const PageWrapper = ({ showHeader = true, children }: IPageWrapperProps) => {
    return (
        <Box component="div" sx={{ height: '100%', margin: 0, display: 'flex', flexDirection: 'column' }}>
            <TopLoadingBar />
            {showHeader && <Header />}
            {children}
            <Footer />
        </Box>
    );
};

export default PageWrapper;
