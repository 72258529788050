import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import environmentReducer from './environment/reducer';
import authReducer from './auth/reducer';
import { IEnvironmentState } from './environment/types';
import { IHeaderState } from './header/types';
import headerReducer from './header/reducer';
import { IAuthState } from './auth/types';
import { IEligibilityState } from './eligibility/types';
import eligibilityReducer from './eligibility/reducer';

export interface IState {
    eligibility: IEligibilityState;
    environment: IEnvironmentState;
    header: IHeaderState;
    auth: IAuthState;
}

const rootReducer = combineReducers<IState>({
    eligibility: eligibilityReducer,
    environment: environmentReducer,
    header: headerReducer,
    auth: authReducer,
});

const middleware = [thunk];

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export type RootState = ReturnType<typeof rootReducer>;

export default store;
