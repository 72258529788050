import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { IProvider } from 'backend/types/provider';
import { useDispatch } from 'react-redux';
import { changeProvider } from 'store/auth/middleware';
import ProviderSelectTable from './ProviderSelectTable';
import { useHistory } from 'react-router-dom';
import { SxProps, Theme } from '@mui/system';

const styles = {
    dialogContent: { p: 2, minWidth: { xs: '80%', md: 800 } } as SxProps<Theme>,
} as const;

interface IProviderSelectDialogProps {
    open: boolean;
    onClose: () => void;
}
const ProviderSelectDialog = ({ open, onClose }: IProviderSelectDialogProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const onProviderSelected = (provider: IProvider) => {
        dispatch(changeProvider(provider.id, true, history));
        if (onClose) onClose();
    };
    return (
        <Dialog maxWidth={false} open={open} onClose={onClose} scroll="paper" aria-labelledby="dialog-title" aria-describedby="dialog-description">
            <DialogTitle id="dialog-title">Select Provider</DialogTitle>
            <DialogContent sx={styles.dialogContent}>
                <Typography component="div" id="dialog-description">
                    <ProviderSelectTable onProviderSelected={onProviderSelected}></ProviderSelectTable>
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

export default ProviderSelectDialog;
