import React from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { logout } from 'store/auth/middleware';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { SxProps, Theme } from '@mui/system';

const styles = {
    signOutButton: {
        marginLeft: '4px',
        letterSpacing: '0px',
    } as SxProps<Theme>,
} as const;

export interface ILogOutButtonProps {
    loginUrl: string;
}

const LogoutButton = ({ loginUrl }: ILogOutButtonProps) => {
    const dispatch = useDispatch();
    return (
        <Button sx={styles.signOutButton} variant="text" startIcon={<ExitToApp />} onClick={() => dispatch(logout(loginUrl))}>
            Sign out
        </Button>
    );
};

export default LogoutButton;
