import LogoutButton from 'components/auth/LogoutButton';
import React from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import VerticalBar from 'components/input/VerticalBar';
import { SxProps, Theme } from '@mui/system';

const styles = {
    loginStyle: {
        fontSize: '14px',
        pr: 1,
        paddingTop: '4px',
    } as SxProps<Theme>,
    userNameLabel: {
        px: 1,
        fontWeight: 500,
        color: 'secondary.dark',
        letterSpacing: '0px',
    } as SxProps<Theme>,
    userName: {
        color: 'secondary.dark',
    } as SxProps<Theme>,
} as const;

interface ILoginProps {
    onProviderClick: () => void;
}
const Login = ({ onProviderClick }: ILoginProps) => {
    const authUser = useSelector((state: IState) => state.auth.currentUser);

    return (
        <>
            <Grid sx={styles.loginStyle} item spacing={0} container justifyContent="flex-end" alignItems="center">
                <Typography sx={styles.userNameLabel}>User:</Typography>
                <Box component="span" sx={styles.userName} data-cy="logged-in-as-user">
                    {authUser.fullName}
                </Box>

                <VerticalBar />
                <Typography sx={styles.userNameLabel}>Provider:</Typography>
                <Box component="span" sx={styles.userName} data-cy="logged-in-as-provider">
                    <Button variant="text" onClick={onProviderClick}>
                        {authUser.providerFullName}
                    </Button>
                </Box>

                <VerticalBar />
                <LogoutButton loginUrl={authUser.loginUrl}></LogoutButton>
            </Grid>
        </>
    );
};

export default Login;
