import React from 'react';
import { Box } from '@mui/material';
import globalStyles from 'config/cssHooks';

interface IVerticalBarProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sx?: any;
}

const VerticalBar = ({ sx }: IVerticalBarProps) => {
    const sxProps = sx ?? {};
    return <Box component="div" sx={{ ...globalStyles.verticalBar, ...sxProps }}></Box>;
};

export default VerticalBar;
