import { DefinitionNode, Kind, ListTypeNode, NonNullTypeNode, OperationDefinitionNode, TypeNode } from 'graphql';
import { ApolloLink } from '@apollo/client';
import { isValidDate, toDateStringOrNull } from './dateTimeHelpers';

function isOperationDefinitionNode(node: DefinitionNode): node is OperationDefinitionNode {
    return node.kind === Kind.OPERATION_DEFINITION;
}

function isListTypeNode(node: TypeNode): node is ListTypeNode {
    return node.kind === Kind.LIST_TYPE;
}

function isNonNullTypeNode(node: TypeNode): node is NonNullTypeNode {
    return node.kind === Kind.NON_NULL_TYPE;
}

function mapIfArray<TOther, TItem, TResponse>(a: TOther | TItem[], fn: (x: TItem) => TResponse): TOther | TResponse[] {
    return Array.isArray(a) ? a.map(fn) : a;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatIfDate(value: any, typeNode: TypeNode): any {
    if (isNonNullTypeNode(typeNode)) {
        return formatIfDate(value, typeNode.type);
    }

    if (isListTypeNode(typeNode)) {
        return mapIfArray(value, (v) => formatIfDate(v, typeNode.type));
    }

    const typeName = typeNode.name.value;
    if (typeName === 'Date') {
        return toDateStringOrNull(value);
    }

    return value;
}

export const dateFormatLink = new ApolloLink((operation, forward) => {
    const o = operation.query.definitions.find(isOperationDefinitionNode);
    const varDefs = o?.variableDefinitions || [];
    varDefs.forEach((vd) => {
        const key = vd.variable.name.value;
        const value = operation.variables[key];
        if (isValidDate(value) && vd.type.kind === 'NamedType') {
            operation.variables[key] = formatIfDate(operation.variables[key], vd.type);
        }
    });
    return forward(operation);
});
